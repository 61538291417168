var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.loading
      ? _c("div", [
          _vm.information.message
            ? _c(
                "div",
                [
                  _c("h4", { staticClass: "m-0 p-0 mt-2 mb-3 link-header" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.information.message.subject),
                      },
                    }),
                  ]),
                  _c("h4", { staticClass: "m-0 p-0 mt-2 mb-1 link-header" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("ORGANISER")) + "\n      "
                    ),
                  ]),
                  _c("UserInfo", {
                    attrs: {
                      image: "/img/default-avatar.png",
                      primary: _vm.createdName,
                      secondary: _vm.information.message.creator.email,
                      optional: _vm.createdTime,
                    },
                  }),
                  _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ml-0 pl-0",
                        staticStyle: { width: "12rem" },
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "m-0 p-0 mt-2 mb-1 link-header" },
                          [_vm._v(_vm._s(_vm.$t("FROM")))]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.FormatDateTime(
                                _vm.information.message.settings
                                  .meeting_start_date
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "ml-0 pl-0",
                        staticStyle: { width: "12rem" },
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "m-0 p-0 mt-2 mb-1 link-header" },
                          [_vm._v(_vm._s(_vm.$t("TO")))]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.FormatDateTime(
                                _vm.information.message.settings
                                  .meeting_end_date
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                  _vm.recurrence
                    ? _c("hr", { staticClass: "pb-0 mb-0 mt-2" })
                    : _vm._e(),
                  _vm.recurrence
                    ? _c("PickRecurrence", {
                        staticClass: "mt-2",
                        attrs: {
                          recurrence: _vm.recurrence,
                          startDate: _vm.message.settings.meeting_start_date,
                          endDate: _vm.message.settings.meeting_end_date,
                          "text-only": "",
                        },
                      })
                    : _vm._e(),
                  _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                  _vm.attachments.length != 0
                    ? _c(
                        "div",
                        { staticClass: "mt-2" },
                        _vm._l(_vm.attachments, function (item, index) {
                          return _c(
                            "span",
                            { key: index, staticClass: "ms-fontSize-12" },
                            [
                              _c("Attachment", {
                                attrs: {
                                  link_uuid: _vm.link_uuid,
                                  item: item,
                                  index: index,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.information.auth_message != "open_to_date" &&
                  _vm.information.auth_message != "open_from_date"
                    ? _c(
                        "div",
                        [
                          _c(
                            "h4",
                            { staticClass: "m-0 p-0 mt-2 mb-1 link-header" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("DESCRIPTION")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _c("BodyText", {
                            attrs: { body: _vm.information.message.body },
                          }),
                          _c("hr", { staticClass: "pb-0 mb-2 mt-2" }),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.JoinMeeting(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "SECURELINK.JITSI.JOIN_MEETING_ANONYMOUS"
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _c("p", [
                            _vm._v(" --  " + _vm._s(_vm.$t("OR")) + " -- "),
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.JoinMeeting(false)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("SECURELINK.JITSI.JOIN_MEETING")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _c(
                            "b-modal",
                            {
                              attrs: {
                                id: "joinMeetingModal",
                                "dialog-class": _vm.teams.theme,
                                "no-header": "",
                                centered: "",
                                "cancel-title": _vm.$t("CANCEL"),
                                "ok-title": _vm.$t("JOIN"),
                              },
                              on: { ok: _vm.Connect },
                              model: {
                                value: _vm.showModal,
                                callback: function ($$v) {
                                  _vm.showModal = $$v
                                },
                                expression: "showModal",
                              },
                            },
                            [
                              _c("center", [
                                _vm.joinAnonymously
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "SECURELINK.JITSI.YOU_WILL_JOIN_AS_ANONYMOUSLY"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.joinAnonymously
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "SECURELINK.JITSI.YOU_WILL_JOIN_AS"
                                          )
                                        ) +
                                          " " +
                                          _vm._s(_vm.information.email)
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }